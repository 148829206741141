import React, { useState, useContext, useEffect } from "react";
import "./reason_devolution.css";
import { useHistory, useParams } from "react-router-dom";
import { WebContext } from "../../Context/Context";
import Loading from "../../../common/Loading";
import { BACKEND_URL } from "../../Utils/constants";

const ReasonRefud = ({ setOpenModal }) => {
  const { loading, setLoading, dates } = useContext(WebContext);
  let params = useParams();
  let { product_id } = params;
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [radio, setRadio] = useState(false);
  const [reason, setReason] = useState({});
  const [idReason, setIdReason] = useState("");
  const [listObjectProducts, setlistObjectProducts] = useState([]);
  let tipo_devolucion = JSON.parse(
    localStorage.getItem("tipo_devolucion", true)
  );

  const closeModal = () => {
    window.localStorage.removeItem("InfoLogin", true);
    push("/");
    setOpenModal(false);
  };

  const changeRadio = ({ target }) => {
    const { name, value } = target;
    setRadio({ ...radio, [name]: value });
    setIdReason(target.id);
  };

  const changeComentary = ({ target }) => {
    const { name, value } = target;
    setRadio({ ...radio, [name]: value });
  };

  const submitReason = () => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    datosDevolucion.razonDevolucion = radio.razon_devolucion;
    datosDevolucion.razonId = idReason;
    datosDevolucion.comentarioRazonDev = radio.comentario;
    window.localStorage.setItem(
      "datosDevolucion",
      JSON.stringify(datosDevolucion)
    );
    window.localStorage.setItem("dates", JSON.stringify(listObjectProducts));
    setLoading(true);
    window.localStorage.setItem("razones_devolucion", JSON.stringify(radio));
    setTimeout(() => {
      setLoading(false);
      if (tipo_devolucion === "Reembolso") {
        return push("/re-payment");
      } else if (tipo_devolucion === "Cambio") {
        return push("/any-reason");
      }
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);
    const findIdProduct = () => {
      let idsProducts = JSON.parse(
        localStorage.getItem("datosDevolucion")
      ).idProducto;
      var listPromises = [];
      for (let index = 0; index < idsProducts.length; index++) {
        const id = idsProducts[index];
        const initialUrl = `${BACKEND_URL}/product-info?key=2c4c5a3b-5289-4b26-9cea-43b955bb1881&product_id=${id}`;
        const promise = new Promise((resolve, reject) => {
          fetch(initialUrl)
            .then((response) => response.json())
            .then(({ data }) => {
              resolve(data);
            });
        });
        listPromises.push(promise);
      }
      Promise.all(listPromises).then((values) => {
        setlistObjectProducts(values);
        setLoading(false);
      });
    };

    const reasonProduct = async () => {
      const initialUrl = `${BACKEND_URL}/store-reason-refund?key=2c4c5a3b-5289-4b26-9cea-43b955bb1881&product_id=${product_id}`;
      fetch(initialUrl)
        .then((response) => response.json())
        .then(({ data }) => {
          setReason(data);
        });
    };
    reasonProduct();
    findIdProduct();
  }, []);

  return (
    <>
      {!!loading ? <Loading /> : false}
      <div className="container-general">
        <div className="container_icons">
          <div
            className="icon__arrow"
            onClick={() => {
              return window.history.back();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </div>
          <h3 className="title__icons">Devoluciones</h3>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general">
          <div className="scroll-container">
            <div id="article-reason">
              <span id="reason-refud">Indica la razón de tu devolución</span>

              <div id="container-radios">
                {Object.entries(reason).length === 0 ? (
                  <>
                    <div className="spinner"></div>
                  </>
                ) : (
                  <>
                    {reason.map((text_reason) => {
                      return (
                        <label
                          key={text_reason.id}
                          htmlFor={text_reason.id}
                          className="label-radio"
                        >
                          <input
                            type="radio"
                            id={text_reason.id}
                            name="razon_devolucion"
                            value={text_reason.name}
                            onChange={changeRadio}
                          />
                          <span>{text_reason.name}</span>
                        </label>
                      );
                    })}
                  </>
                )}
              </div>

              <div id="container-input">
                <label htmlFor="comentary" id="comentary-label">
                  Comentarios (opcional)
                </label>
                <textarea
                  type="text"
                  id="comentary"
                  name="comentario"
                  placeholder="Escribe aquí"
                  onChange={changeComentary}
                />
              </div>

              {!radio.razon_devolucion ? (
                <>
                  <div id="center-btn-order-r">
                    <button id="btn-reason">
                      <span>Confirmar devolución</span>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div id="center-btn-order-r" onClick={submitReason}>
                    <button
                      disabled={listObjectProducts.length === 0}
                      id="btn-reason-active"
                    >
                      <span>Confirmar devolución</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <>
          <div className="background">
            <div className="popup">
              <div className="content">
                ¿Seguro que deseas salir? Todo el estado actual se perderá.
              </div>
              <div className="container-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button className="btn-accept" onClick={closeModal}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
};

export default ReasonRefud;
