import React, { useState, useContext, useEffect } from "react";
import "./Index.css";
import { WebContext } from "../../../Context/Context";
import { useHistory } from "react-router-dom";
import Loading from "../../../../common/Loading";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BACKEND_URL } from "../../../Utils/constants";

const AnyReason = ({ setOpenModal }) => {
  const { loading, setLoading, dates } = useContext(WebContext);
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [radios, setRadios] = useState(false);
  const [returnPlace, setReturnPlace] = useState({});
  const [totalMontoChange, setTotalMontoChange] = useState(0);

  const [indexCarousel, setIndexCarousel] = useState(0);

  useEffect(() => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    setTotalMontoChange(Number(datosDevolucion.total).toFixed(2));
  }, [totalMontoChange]);

  const closeModal = () => {
    push("/");
    setOpenModal(false);
  };

  const changeRadios = ({ target }) => {
    setRadios(target.value);
  };

  const onChangePage = () => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    datosDevolucion.metodoDevolucion = radios;
    window.localStorage.setItem(
      "datosDevolucion",
      JSON.stringify(datosDevolucion)
    );
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (
        radios === "Devolver tu producto en Tienda" ||
        radios === "Enviar a Correo/Almacén"
      ) {
        return push("/return-product");
      } else if (radios === "Devolver tu producto en Domicilio") {
        return push("/address-devolution");
      }
    }, 1000);
    window.localStorage.setItem("devolver_producto", radios);
  };

  useEffect(() => {
    const placeReturn = () => {
      const url = `${BACKEND_URL}/place-of-return?key=2c4c5a3b-5289-4b26-9cea-43b955bb1881`;
      fetch(url)
        .then((res) => res.json())
        .then(({ data }) => {
          setReturnPlace(data);
        });
    };
    placeReturn();
  }, []);

  const setSelectedPage = (index) => {
    setIndexCarousel(index);
  };

  return (
    <>
      {!!loading ? <Loading /> : false}
      <div className="container-general">
        <div className="container_icons">
          <div
            className="icon__arrow"
            onClick={() => {
              return window.history.back();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </div>
          <h3 className="title__icons" style={{transform: "translateX(-14px)"}}>Devoluciones</h3>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general">
          <div id="anyReasonScrollContainer">
            <div id="any-container">
              <div id="article-any">
                <div className="carousel-wrapper">
                  <div id="container-about-any-reason">
                    <Carousel
                      onChange={setSelectedPage}
                      showThumbs={false}
                      statusFormatter={(currentItem, total) =>
                        `${currentItem} de ${total}`
                      }
                    >
                      {dates.map((items, i) => {
                        console.log(items);
                        return (
                          <div key={i} id={items.id}>
                            <div id={i}>
                              <img src={items.images[0].http_path} />
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>

                <div id="anyReasonTitleContainer">
                  <div>
                    <div id="any-flex">
                      <span>
                        {dates[indexCarousel].name}{" "}
                        {dates[indexCarousel].u_model}
                      </span>
                    </div>
                    <span id="ref-any">Ref. {dates[indexCarousel].id}</span>
                    <div id="span-any-p">
                      <span>Cantidad: {dates[indexCarousel].quantity}</span>
                    </div>
                  </div>
                  <div>
                    <span>
                      {Number(dates[indexCarousel].price).toFixed(2)}€
                    </span>
                  </div>
                </div>
              </div>

              <div id="div-total">
                <p id="title-message_store">
                  Total valor devolución: {totalMontoChange}€
                </p>
              </div>

              <div id="any-options">
                <span id="span-any">Selecciona un método de devolución</span>

                <div id="container-radios__any">
                  {returnPlace.has_return_store === 1 ? (
                    <>
                      <div>
                        <div className="container-radio">
                          <label htmlFor="test1" className="label-radio">
                            <input
                              type="radio"
                              id="test1"
                              name="radio-group"
                              value="Devolver tu producto en Tienda"
                              onChange={changeRadios}
                            />
                            <span>Devolver tu producto en Tienda</span>
                          </label>
                          <span className="span-free">Gratuito</span>
                        </div>
                        <div>
                          <p className="any-date">
                            Estimada hasta el jueves 10 sep. - martes 15 sep.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}

                  {returnPlace.has_return_home === 1 ? (
                    <>
                      <div>
                        <div className="container-radio">
                          <label htmlFor="test2" className="label-radio">
                            <input
                              type="radio"
                              id="test2"
                              name="radio-group"
                              value="Devolver tu producto en Domicilio"
                              onChange={changeRadios}
                            />
                            <span>Devolver tu producto en Domicilio</span>
                          </label>
                          <span className="span-free">Gratuito</span>
                        </div>
                        <div>
                          <p className="any-date">
                            Estimada hasta el jueves 10 sep. - martes 15 sep.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}

                  {returnPlace.has_return_mail === 1 ? (
                    <>
                      <div>
                        <div className="container-radio">
                          <label htmlFor="test3" className="label-radio">
                            <input
                              type="radio"
                              id="test3"
                              name="radio-group"
                              value="Enviar a Correo/Almacén"
                              onChange={changeRadios}
                            />
                            <span>Enviar a Correo/Almacén</span>
                          </label>
                          <span className="span-free">Gratuito</span>
                        </div>
                        <div>
                          <p className="any-date">
                            Estimada hasta el jueves 10 sep. - martes 15 sep.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                </div>
              </div>

              {radios === false ? (
                <>
                  <div id="center-btn-razon">
                    <button id="any-btnNext">Continuar</button>
                  </div>
                </>
              ) : (
                <>
                  <div id="center-btn-razon">
                    <button id="any-btnNext-active" onClick={onChangePage}>
                      Continuar
                    </button>
                  </div>
                </>
              )}
              <br />
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <>
          <div className="background">
            <div className="popup">
              <div className="content">
                ¿Seguro que deseas salir? Todo el estado actual se perderá.
              </div>
              <div className="container-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button className="btn-accept" onClick={closeModal}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
};

export default AnyReason;
