import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./address_devolution.css";
import Loading from "../../../common/Loading";
import More from "../../../assets/icono-mais@2x.png";
import Pensil from "../../../assets/icon-pencil.png";
import { BACKEND_URL } from "../../Utils/constants";
import { WebContext } from "../../Context/Context";

const Address_devolution = ({ setOpenModal }) => {
  let order_id = JSON.parse(window.localStorage.getItem("order_id", true));
  const { loading, setLoading } = useContext(WebContext);
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [addressUser, setAddressUser] = useState({});
  const [changeAddress, setChangeAddress] = useState({});
  const [aceptaTerminos, setAceptaTerminos] = useState(false);

  const handleOnChange = () => {
    setAceptaTerminos(!aceptaTerminos);
  };

  const closeModal = () => {
    window.localStorage.removeItem("InfoLogin", true);
    push("/");
    setOpenModal(false);
  };

  useEffect(() => {
    const getAddress = async () => {
      const address = `${BACKEND_URL}/order-address?key=2c4c5a3b-5289-4b26-9cea-43b955bb1881&order_id=${order_id}`;
      fetch(address)
        .then((response) => response.json())
        .then(({ data }) => {
          setAddressUser(data);
          setChangeAddress({
            nombre: data.customer.first_name,
            apellido: data.customer.last_name,
            ciudad: data.shipping_address.city,
            linea1: data.shipping_address.address1,
            linea2: data.shipping_address.address2,
            codigo_postal: data.shipping_address.postcode,
            pais: data.shipping_address.country.name,
          });
        });
    };
    getAddress();
  }, []);

  const editAddress = () => {
    push("/return-home");
  };

  const onConfirmRefound = () => {
    setLoading(true);
    window.localStorage.setItem("address", JSON.stringify(changeAddress));
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    datosDevolucion.items = datosDevolucion.idProducto.length;
    datosDevolucion.address = JSON.stringify(changeAddress);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 229|qoCZFXmUyxbmMVD9O19Wdq5cyCkcchStjOrGfozq",
        Accept: "application/json",
      },
      body: JSON.stringify(datosDevolucion),
    };

    const initialUrl = `${BACKEND_URL}/register-order`;
    fetch(initialUrl, requestOptions)
      .then((response) => response.json())
      .then(({ data }) => {
        window.localStorage.setItem("codigoDevolucionServ", data.order_code);
        setLoading(false);
        push("/view-refud-success");
      });
  };

  return (
    <>
      {!!loading ? <Loading /> : false}
      <div className="container-general">
        <div className="container_icons">
          <div
            className="icon__arrow"
            onClick={() => {
              return window.history.back();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </div>
          <h3 className="title__icons">Devoluciones</h3>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general">
          <div className="scroll-container">
            {Object.entries(addressUser).length === 0 ? (
              <Loading />
            ) : (
              <>
                <div>
                  <div id="container-btn-radio-two">
                    <div>
                      <div className="container-radio">
                        <label htmlFor="test" className="label-radio">
                          <input
                            type="radio"
                            id="test1"
                            name="radio-group"
                            checked
                          />
                          <span>Devolver tu producto en Domicilio</span>
                        </label>
                        <span id="span-free__home-two">Gratuito</span>
                      </div>
                      <div>
                        <p className="home-date">
                          Estimada hasta al jueves 10 Mar. - martes 15 mar.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div id="container-address">
                    <p id="text-dates">Datos dirección</p>
                    <div id="article-address">
                      <div id="flex-address">
                        <p>
                          {addressUser?.customer?.first_name}{" "}
                          {addressUser?.customer?.last_name}
                        </p>
                        <img
                          src={Pensil}
                          alt="edit"
                          width={25}
                          height={25}
                          onClick={editAddress}
                        />
                      </div>
                      <span>Calle Pallars, 400</span>
                      <span>2-2</span>
                      <span>
                        {addressUser?.shipping_address?.postcode} -{" "}
                        {addressUser?.shipping_address?.country?.name}
                      </span>
                      <span>+34600000000</span>
                    </div>
                    <div id="more-contaner" onClick={editAddress}>
                      <img src={More} alt="more" />
                      <p id="add-address">Añadir dirección</p>
                    </div>
                  </div>
                  <div id="term-home-devolution-container">
                    <input
                      type="checkbox"
                      id="term-home-devolution-check"
                      name="terms"
                      onChange={handleOnChange}
                    />
                    <label
                      id="term-home-devolution"
                      htmlFor="term-home-devolution-check"
                    >
                      Estoy de acuerdo con los
                      <span id="terminos-home-devolution">
                        Términos y Condiciones
                      </span>
                    </label>
                  </div>
                </div>

                <div id="center-btn-address-dev">
                  {!aceptaTerminos ? (
                    <>
                      <button id="bt-home-address">Confirmar devolución</button>
                    </>
                  ) : (
                    <>
                      <button
                        id="bt-home-active-address"
                        onClick={() => onConfirmRefound()}
                      >
                        Confirmar devolución
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {open ? (
        <>
          <div className="background">
            <div className="popup">
              <div className="content">
                ¿Seguro que deseas salir? Todo el estado actual se perderá.
              </div>
              <div className="container-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button className="btn-accept" onClick={closeModal}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
};

export default Address_devolution;
