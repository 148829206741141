import React, { useState, useContext } from "react";
import { WebContext } from "../../Context/Context";
import { useHistory } from "react-router-dom";
import "./credit_card.css";
import Loading from "../../../common/Loading";
import { BACKEND_URL } from "../../Utils/constants";

const ReturnCard = ({ setOpenModal }) => {
  const { dates, loading, setLoading } = useContext(WebContext);
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [datesCard, setDatesCard] = useState({});
  const [errors, setErrors] = useState({});
  const { name, value } = errors ?? false;
  const errorName = (name ?? [])[0];
  const errorValue = (value ?? [])[0];

  const closeModal = () => {
    window.localStorage.removeItem("InfoLogin", true);
    window.localStorage.removeItem("datosDevolucion");
    push("/");
    setOpenModal(false);
  };

  const getDates = ({ target }) => {
    const { name, value } = target;
    if (name === "value") {
      setDatesCard({ ...datesCard, [name]: value.slice(0, 16) });
    } else {
      setDatesCard({ ...datesCard, [name]: value });
    }
  };

  const validateCard = () => {
    setLoading(true);
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    datosDevolucion.nombreTitular = datesCard.name;
    datosDevolucion.numeroIBAN = datesCard.value;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 229|qoCZFXmUyxbmMVD9O19Wdq5cyCkcchStjOrGfozq",
        Accept: "application/json",
      },
      body: JSON.stringify(datosDevolucion),
    };

    const initialUrl = `${BACKEND_URL}/register-order`;
    fetch(initialUrl, requestOptions)
      .then((response) => response.json())
      .then(({ data }) => {
        window.localStorage.setItem("codigoDevolucionServ", data.order_code);
        setLoading(false);
        push("/view-refud-success");
      });
  };

  return (
    <>
      {!!loading ? <Loading /> : false}
      <div className="container-general">
        <div className="container_icons">
          <div
            className="icon__arrow"
            onClick={() => {
              return window.history.back();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </div>
          <h3 className="title__icons">Devoluciones</h3>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general" id="creditCardScroll">
          <div id="return-card__container">
            <div id="creditCardTiteContainer">
              <p className="card-date">
                Por favor, introduce a continuación tus datos bancarios para
                proceder al reembolso
              </p>
            </div>

            <div id="div-inputs__card">
              <div id="creditCardInputContainer">
                <span id="creditCardInputLabel">
                  Nombre del titular de la cuenta:
                </span>
                <input
                  type="text"
                  maxLength={20}
                  id="titular"
                  name="name"
                  onChange={getDates}
                  value={datesCard.name}
                />
                {errorName ? (
                  <>
                    <div className="message-error">{errorName}</div>
                  </>
                ) : (
                  false
                )}
              </div>
              <div id="creditCardInputContainer">
                <span id="creditCardInputLabel">Número de IBAN:</span>
                <input
                  type="number"
                  id="card_number"
                  name="value"
                  onChange={getDates}
                  value={datesCard.value}
                />
                {errorValue ? (
                  <>
                    <div className="message-error">{errorValue}</div>
                  </>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>

          {datesCard.value === undefined ||
          datesCard.value.length === 0 ||
          datesCard.name === undefined ||
          datesCard.name.length === 0 ? (
            <>
              <button id="bt-card">Terminar devolución</button>
            </>
          ) : (
            <>
              <button id="bt-card-active" onClick={validateCard}>
                Terminar devolución
              </button>
            </>
          )}
        </div>
      </div>

      {open ? (
        <>
          <div className="background">
            <div className="popup">
              <div className="content">
                ¿Seguro que deseas salir? Todo el estado actual se perderá.
              </div>
              <div className="container-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button className="btn-accept" onClick={closeModal}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
};

export default ReturnCard;
