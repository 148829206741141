import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../../common/Loading";
import { WebContext } from "../../Context/Context";
import { BACKEND_URL } from "../../Utils/constants";
import "./store.css";

const ReturnProductStore = ({ setOpenModal }) => {
  const { setLoading, loading } = useContext(WebContext);
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  let product_id = JSON.parse(window.localStorage.getItem("product_id", true));
  const [stores, setStores] = useState([]);
  const [idStore, setIdStore] = useState({});
  const [activeButon, setActiveButon] = useState(false);
  const [nameStore, setNameStore] = useState("");

  const closeModal = () => {
    window.localStorage.removeItem("InfoLogin", true);
    push("/");
    setOpenModal(false);
  };

  useEffect(() => {
    const findId = async () => {
      const initialUrl = `${BACKEND_URL}/stores-branchs?key=2c4c5a3b-5289-4b26-9cea-43b955bb1881`;
      fetch(initialUrl)
        .then((response) => response.json())
        .then(({ data }) => {
          setStores(data);
        });
    };
    findId();
  }, []);

  const changeStore = (target) => {
    setIdStore(target.id);
    setActiveButon(true);
    setNameStore(target.name);
  };

  const getCodeStore = () => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    datosDevolucion.idTienda = idStore;
    datosDevolucion.nombreTienda = nameStore;
    datosDevolucion.items = datosDevolucion.idProducto.length;
    window.localStorage.setItem(
      "datosDevolucion",
      JSON.stringify(datosDevolucion)
    );
    window.localStorage.removeItem("codigoDevolucionServ");

    createDevolution();

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      push("/view-refud-success");
    }, 2500);
  };

  const createDevolution = async () => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 229|qoCZFXmUyxbmMVD9O19Wdq5cyCkcchStjOrGfozq",
        Accept: "application/json",
      },
      body: JSON.stringify(datosDevolucion),
    };

    const initialUrl = `${BACKEND_URL}/register-order`;
    fetch(initialUrl, requestOptions)
      .then((response) => response.json())
      .then(({ data }) => {
        window.localStorage.setItem("codigoDevolucionServ", data.order_code);
      });
  };

  return (
    <>
      {!!loading ? <Loading /> : false}
      <div className="container-general">
        <div className="container_icons_store">
          <div
            className="icon__arrow"
            onClick={() => {
              return window.history.back();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </div>
          <h3 className="title__icons">Devoluciones</h3>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general">
          <div className="scroll-container">
            <div id="return-store">
              <div>
                <div className="container-radio">
                  <label htmlFor="test" className="label-radio">
                    <input type="radio" id="test1" name="radio-group" checked />
                    <span>Devolver tu producto en Tienda</span>
                  </label>
                  <span id="span-store">Gratuito</span>
                </div>
                <div>
                  <p className="store-date">
                    Estimada hasta al marte 08 mar. - martes 15 mar.
                  </p>
                </div>
                <p id="paragraph-store">Elegir tienda</p>
              </div>
            </div>

            <div id="container-stores">
              <div id="stores">
                {Object.entries(stores).length === 0 ? (
                  <>
                    <div className="spinner2"></div>
                  </>
                ) : (
                  <>
                    {stores.map((items) => {
                      return (
                        <div key={items.id} id="stores-div">
                          <label htmlFor={items.id} className="label-radio">
                            <input
                              type="radio"
                              id={items.id}
                              value={items.id}
                              name="stores"
                              onChange={() => {
                                changeStore({ id: items.id, name: items.name });
                              }}
                            />
                            <span>{items.name}</span>
                          </label>

                          <div>
                            <p className="store-date">{items.address}</p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>

            <div id="center-btn-order-s">
              {!activeButon ? (
                <>
                  <button id="btn-stores">Confirmar devolución</button>
                </>
              ) : (
                <>
                  <button id="btn-stores-active" onClick={getCodeStore}>
                    Confirmar devolución
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <>
          <div className="background">
            <div className="popup">
              <div className="content">
                ¿Seguro que deseas salir? Todo el estado actual se perderá.
              </div>
              <div className="container-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button className="btn-accept" onClick={closeModal}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
};

export default ReturnProductStore;
