import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./code_devolution.css";

const ViewRefudSuccess = ({ setOpenModal }) => {
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [nameStore, setNameStore] = useState("");
  const [codeDevolution, setCodeDevolution] = useState("");

  useEffect(() => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    let codeDevo = localStorage.getItem("codigoDevolucionServ");
    if (datosDevolucion.nombreTienda) {
      setNameStore(datosDevolucion.nombreTienda);
    } else {
      setNameStore(null);
    }

    setCodeDevolution(codeDevo);
  }, [nameStore]);

  const closeModal = () => {
    window.localStorage.removeItem("datosDevolucion");
    window.localStorage.removeItem("InfoLogin", true);
    push("/");
    setOpenModal(false);
  };

  const endDevolution = () => {
    window.localStorage.removeItem("datosDevolucion");
    push("/questionnaire");
  };

  return (
    <>
      <div className="container-general">
        <br />

        <div className="container_icons">
          <h3 className="title__icons">Devoluciones</h3>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general">
          <div className="scroll-container">
            <div id="article-view">
              <p id="title-message">
                Tu solicitud de devolución se ha efectuado con éxito.
              </p>
              <span id="paragraph-message">
                Hemos enviado a tu correo electrónico el código de devolución.
                Muestra el código para devolver tu producto.
              </span>
              <p id="small-message">Código devolución:</p>
              <input
                type="text"
                id="code-devolution"
                value={codeDevolution}
                disabled
              />
              {nameStore !== null ? (
                <div id="codigo-rembolso">
                  <p id="title-message_store">
                    Puedes utilizar este código en: {nameStore}
                  </p>
                </div>
              ) : null}
              <button
                id="view-btn"
                onClick={() => {
                  endDevolution();
                }}
              >
                Terminar devolución
              </button>
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <>
          <div className="background">
            <div className="popup">
              <div className="content">
                ¿Seguro que deseas salir? Todo el estado actual se perderá.
              </div>
              <div className="container-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button className="btn-accept" onClick={closeModal}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
};

export default ViewRefudSuccess;
