import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../../common/Loading";
import { WebContext } from "../../../Context/Context";
import { BACKEND_URL } from "../../../Utils/constants";
import "./list_products.css";

const Products = ({ setOpenModal }) => {
  const { loading, setLoading } = useContext(WebContext);
  let params = useParams();
  let { order_id } = params;
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState({});
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectProductItem, setSelectProductItem] = useState([]);

  const [dateOrder, setDateOrder] = useState("");
  const [numProductsOrder, setNumProductsOrder] = useState("");
  const [totalOrder, setTotalOrder] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [checkedAllState, setCheckedAllState] = useState(false);

  const [totalChange, setTotalChange] = useState(0);

  const [listObjectProducts, setlistObjectProducts] = useState([]);

  let tipo_devolucion = JSON.parse(
    localStorage.getItem("tipo_devolucion", true)
  );
  useEffect(() => {
    if (selectProduct.length !== 0) {
      window.localStorage.setItem("product_id", JSON.stringify(selectProduct));
      window.localStorage.setItem("order_id", JSON.stringify(order_id));
    }
  }, [selectProduct]);

  useEffect(() => {
    setCheckedState(new Array(products.length).fill(false));
    setTotalChange(0);
    setSelectProduct(new Array(products.length).fill(null));
  }, [products]);

  const closeModal = () => {
    window.localStorage.removeItem("InfoLogin", true);
    push("/");
    setOpenModal(false);
  };

  useEffect(() => {
    let selectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
    setDateOrder(selectedOrder.date);
    setNumProductsOrder(selectedOrder.numProducts);
    setTotalOrder(selectedOrder.total);
    const findId = async () => {
      const initialUrl = `${BACKEND_URL}/order-info?key=2c4c5a3b-5289-4b26-9cea-43b955bb1881&order_id=${order_id}`;
      fetch(initialUrl)
        .then((response) => response.json())
        .then(({ order_products }) => {
          setProducts(order_products);
        });
    };
    findId();
  }, []);

  const changePages = () => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    datosDevolucion.idProducto = selectProduct.filter((id) => id != null);
    datosDevolucion.listaProductos = selectProductItem;
    datosDevolucion.tipoDevolucion = tipo_devolucion;
    datosDevolucion.total = totalChange;
    window.localStorage.setItem(
      "datosDevolucion",
      JSON.stringify(datosDevolucion)
    );
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (tipo_devolucion === "Reembolso") {
        return push(
          `/reason-refud/${selectProduct.filter((id) => id != null)}`
        );
      } else if (tipo_devolucion === "Cambio") {
        push(`/reason-refud/${selectProduct.filter((id) => id != null)}`);
      }
    }, 1000);
  };

  const handleOnChange = (position, items) => {
    console.log(position, checkedState);
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + products[index].price;
        }
        return sum;
      },
      0
    );

    setTotalChange(totalPrice);

    for (let index = 0; index < selectProduct.length; index++) {
      if (index === position) {
        if (updatedCheckedState[position]) {
          selectProduct[position] = products[index].product_id;
        } else {
          selectProduct[position] = null;
        }
      }
    }

    const productSelected = selectProductItem.find(
      (x) => x.product_id === items.product_id
    );
    if (productSelected) {
      const newList = selectProductItem.filter(
        (x) => x.product_id !== items.product_id
      );
      setSelectProductItem(newList);
    } else {
      const newList = [...selectProductItem];
      newList.push(items);
      setSelectProductItem(newList);
    }
  };

  const selectdProduct = (item, index) => {
    setSelectProduct([item.product_id]);
    setSelectProductItem([item]);
    setTotalChange(products[index].price);
  };

  const selectdAllProduct = () => {
    setCheckedAllState(!checkedAllState);
    if (!checkedAllState) {
      let total = 0;
      const currentProductos = [...selectProductItem];
      for (let index = 0; index < products.length; index++) {
        if (products[index].quantity_in_inventory !== 0) {
          checkedState[index] = true;
          selectProduct[index] = products[index].product_id;
          currentProductos.push(products[index]);
          total = total + products[index].price;
        }
      }
      setSelectProductItem(currentProductos);
      setTotalChange(total);
    } else {
      setCheckedState(new Array(products.length).fill(false));
      setSelectProduct(new Array(products.length).fill(null));
      setSelectProductItem([]);
      setTotalChange(0);
    }
  };

  return (
    <>
      {!!loading ? <Loading /> : false}
      <div className="container-general">
        <div className="container_icons_p">
          <div
            className="icon__arrow"
            onClick={() => {
              return window.history.back();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </div>
          <div className="center-content">
            <h3 className="title__icons_p">Pedido n° {order_id}</h3>
            <p className="text-normal">
              Fecha: {dateOrder} - Productos: {numProductsOrder} - {totalOrder}€
            </p>
          </div>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general">
          <div className="scroll-container">
            <div id="article-product">
              {Object.entries(products).length === 0 ? (
                <>
                  <div className="background-spinner">
                    <span className="spinner"></span>
                  </div>
                </>
              ) : (
                <>
                  <div id="divCheckAll">
                    <div id="productsHeaderContainer">
                      <input
                        type="checkbox"
                        id="selectAll"
                        name="my-checkbox"
                        onChange={() => selectdAllProduct()}
                        checked={checkedAllState}
                      />
                      <label htmlFor="selectAll">Selecionar todo</label>
                      <div id="productsSelectionContainer">
                        {selectProduct.filter((id) => id != null).length !==
                        0 ? (
                          <div>
                            <span id="numProducts">
                              {selectProduct.filter((id) => id != null).length}
                              {selectProduct.filter((id) => id != null)
                                .length === 1
                                ? " producto"
                                : " productos"}
                            </span>
                          </div>
                        ) : (
                          false
                        )}
                        <div id="center-btn-order-p">
                          {totalChange === 0 ? (
                            <>
                              <button id="start-products-disabled">
                                <span>Iniciar devolución</span>
                              </button>
                            </>
                          ) : (
                            <>
                              <button id="start-products" onClick={changePages}>
                                <span>Iniciar devolución</span>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {products.map((items, index) => {
                    return (
                      <div key={index} id="item-product">
                        <div id="items">
                          <div id="container-checkBox">
                            {items.quantity_in_inventory === 0 ? (
                              <div style={{ marginLeft: "30px" }}></div>
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  id={index}
                                  name="my-checkbox"
                                  onChange={() => handleOnChange(index, items)}
                                  checked={checkedState[index]}
                                />
                                <label htmlFor={index}>
                                  <input
                                    type="checkbox"
                                    id={index}
                                    name="my-checkbox"
                                    onChange={() =>
                                      selectdProduct(items, index)
                                    }
                                  />
                                  <span></span>
                                </label>
                              </>
                            )}
                          </div>

                          {items.images.length === 0 ? (
                            <>
                              <img
                                src="https://us.123rf.com/450wm/urfandadashov/urfandadashov1805/urfandadashov180500070/100957966-icono-de-foto-no-disponible-aislado-sobre-fondo-blanco-ilustraci%C3%B3n-vectorial.jpg?ver=6"
                                alt=""
                                width={200}
                                height={180}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={items.images[0]?.http_path}
                                alt=""
                                width={200}
                                height={180}
                              />
                            </>
                          )}

                          <div>
                            <p id="text-header">
                              {items.name}
                              <br />
                              {items.model}
                            </p>
                            <p className="text-normal">
                              Ref. {items.product_id}
                            </p>
                            <p className="text-normal">
                              Cantidad: {items.quantity}
                            </p>
                          </div>
                          <p id="text-header">{items.price}€</p>
                        </div>
                        {items.quantity_in_inventory === 0 ? (
                          <>
                            <small id="not-change">
                              No disponible para cambio
                            </small>
                          </>
                        ) : (
                          false
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>

        {open ? (
          <>
            <div className="background">
              <div className="popup">
                <div className="content">
                  ¿Seguro que deseas salir? Todo el estado actual se perderá.
                </div>
                <div className="container-btn">
                  <button
                    className="btn-cancel"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancelar
                  </button>
                  <button className="btn-accept" onClick={closeModal}>
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          false
        )}
      </div>
    </>
  );
};

export default Products;
