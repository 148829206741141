import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { WebContext } from "../../../Context/Context";
import "./Index.css";
import Loading from "../../../../common/Loading";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const RePayment = ({ setOpenModal }) => {
  const { loading, setLoading, dates } = useContext(WebContext);
  const [checkbox, setCheckbox] = useState(false);
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [totalMontoChange, setTotalMontoChange] = useState(0);
  const [indexCarousel, setIndexCarousel] = useState(0);

  const closeModal = () => {
    window.localStorage.removeItem("InfoLogin", true);
    push("/");
    setOpenModal(false);
  };

  const radiosChangeRefud = ({ target }) => {
    setCheckbox(target.value);
  };

  useEffect(() => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    setTotalMontoChange(Number(datosDevolucion.total).toFixed(2));
  }, [totalMontoChange]);

  const onChangePages = () => {
    let datosDevolucion = JSON.parse(localStorage.getItem("datosDevolucion"));
    datosDevolucion.metodoReembolso = checkbox;
    datosDevolucion.items = datosDevolucion.idProducto.length;
    window.localStorage.setItem(
      "datosDevolucion",
      JSON.stringify(datosDevolucion)
    );

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (checkbox === "Reembolso a una tarjeta") {
        return push("/return-card");
      }
    }, 2500);
    window.localStorage.setItem("producto_reembolso", checkbox);
  };

  const setSelectedPage = (index) => {
    setIndexCarousel(index);
  };

  return (
    <>
      {!!loading ? <Loading /> : false}
      <div className="container-general">
        <div className="container_icons">
          <div
            className="icon__arrow"
            onClick={() => {
              return window.history.back();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </div>
          <h3 className="title__icons" style={{transform: "translateX(-14px)"}}>Devoluciones</h3>
          <div
            className="icon__close"
            onClick={() => {
              setOpen(true);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>

        <div className="flex-general">
          <div id="rePaymentScrollContainer">
            <div id="rePaymentContainer">
              <p>Tu reembolso se efectuará en el instante.</p>
              <div id="article-re">
                <div className="carousel-wrapper">
                  <div id="container-about-any-reason">
                    <Carousel
                      onChange={setSelectedPage}
                      showThumbs={false}
                      statusFormatter={(currentItem, total) =>
                        `${currentItem} de ${total}`
                      }
                    >
                      {dates.map((items, i) => {
                        return (
                          <div key={i} id={items.id}>
                            <div id={i}>
                              <img src={items.images[0].http_path} />
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>

                <div id="rePaymentItemTitle">
                  <div id="re-flex">
                    <div>
                      <div>
                        {dates ? (
                          <>
                            <span>
                              {dates[indexCarousel].name}{" "}
                              {dates[indexCarousel].u_model}
                            </span>
                          </>
                        ) : (
                          false
                        )}
                      </div>
                      <div>
                        {dates ? (
                          <>
                            <p id="ref-re">Ref. {dates[indexCarousel].id}</p>
                          </>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                    <div>
                      {dates ? (
                        <>
                          <span>
                            {Number(dates[indexCarousel].price).toFixed(2)}€
                          </span>
                        </>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div id="div-total">
                <p id="title-message_store">
                  Total valor devolución: {totalMontoChange}€
                </p>
              </div>
              <div id="re-options">
                <span id="span-re">Selecciona un método de devolución</span>

                <div id="container-radios__re">
                  <div>
                    <div className="container-radio">
                      <label htmlFor="test1" className="label-radio">
                        <input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          value="Reembolso a una tarjeta"
                          onChange={radiosChangeRefud}
                        />
                        <span>Reembolso a una tarjeta</span>
                      </label>
                      {dates ? (
                        <>
                          <span>{totalMontoChange}€</span>
                        </>
                      ) : (
                        false
                      )}
                    </div>
                    <div>
                      <p className="any-date">
                        Estimada hasta el martes 08 sep. - martes 15 sep.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {checkbox === false ? (
                <>
                  <div id="center-btn-next">
                    <button id="re-btnNext">Continuar reembolso</button>
                  </div>
                </>
              ) : (
                <>
                  <div id="center-btn-next">
                    <button id="re-btnNext-active" onClick={onChangePages}>
                      Continuar reembolso
                    </button>
                  </div>
                </>
              )}
              <br />
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <>
          <div className="background">
            <div className="popup">
              <div className="content">
                ¿Seguro que deseas salir? Todo el estado actual se perderá.
              </div>
              <div className="container-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button className="btn-accept" onClick={closeModal}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
};

export default RePayment;
